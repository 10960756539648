import Cookies from 'js-cookie';

import { paths } from 'src/routes/paths';

import axios from 'src/utils/axios';

// ----------------------------------------------------------------------

export default function jwtDecode(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    Buffer.from(base64, 'base64')
      .toString('binary')
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------

export const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// ----------------------------------------------------------------------


let expiredTimer: ReturnType<typeof setTimeout>;

export const tokenExpired = (exp: number) => {
 const expInMilliseconds = exp * 1000;
const currentTime = Date.now(); 
const timeLeft = expInMilliseconds - currentTime;

console.log('exp', exp);
console.log('currentTime', currentTime);
console.log('timeLeft', timeLeft);

// clearTimeout(expiredTimer);

// if (timeLeft > 0) {
//   expiredTimer = setTimeout(() => {
//     try {
//       alert('Token expired');
//       localStorage.removeItem('accessToken');
//       // window.location.href = paths.auth.jwt.login;
//     } catch (error) {
//       console.error('Error handling token expiration:', error);
//     }
//   }, timeLeft); 
// } else {
//   console.log('Token is already expired');
// }
};
// ----------------------------------------------------------------------

export const setSession = (key: string, user: string | null) => {
  if (user) {
    sessionStorage.setItem(key, user);
  } else {
    sessionStorage.removeItem(key);

  }
};

// ----------------------------------------------------------------------

export const setLocalStorage = (key: string, accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem(key, accessToken);

    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    const { exp } = jwtDecode(accessToken);
    tokenExpired(exp);
  } else {
    localStorage.removeItem(key);

    delete axios.defaults.headers.common.Authorization;
  }
};

// ----------------------------------------------------------------------

export const setCookie = (key: string, accessToken: string | null) => {
  if (accessToken) {
    Cookies.set(key, accessToken);
    const { exp } = jwtDecode(accessToken);
    tokenExpired(exp);
  } else {
    Cookies.remove(key);
  }
};


// ----------------------------------------------------------------------
